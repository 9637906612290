export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const LOCATION = '/location';
export const ABOUT_US = '/about';
export const PROMOTIONS = '/promotions';
export const FAQS = '/faqs';
export const MENUS = '/menus';
export const GIFT_CARDS = '/gift';
export const SHOP_GEAR = '/shop';
export const CONTACT = '/contact-us';
export const EVENTS = '/events';
export const CAREERS = '/careers';
export const LESSONS = '/lessons';
export const LEAGUES = '/leagues';
export const AD = '/ad';
export const ADMH = '/admh';
export const MESSAGING = '/messaging';
export const SIGNTV = '/signtv';
export const COURSE_TEESHEET = '/teesheet';
export const BEERMH = '/beer-menu/:id';
export const BEER_ADMIN = '/beer';
export const CALENDAR_ADMIN = '/calendar-admin'
export const REVIEWS = '/reviews'
export const MEMBERSHIP = '/membership'
export const TOUR = '/tour'

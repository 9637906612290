import { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import * as ROLES from '../../constants/roles';
import './index.scss';

function BeerAdminPage(props) {
  const [locations, setLocations] = useState([]);
  const [selectedLocationObject, setSelectedLocationObject] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationName, setLocationName] = useState('');
  const [beerList, setBeerList] = useState([]);
  const [specialsList, setSpecialsList] = useState([]);
  const [wineList, setWineList] = useState([]);
  const [cannedBeerList, setCannedBeerList] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const querySnapshotBeers = await props.firebase.beers().get();
      const beersData = querySnapshotBeers.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        showBeer: doc.data()?.show,
      }));

      const querySnapshotSpecials = await props.firebase.specials().get();
      const specialsData = querySnapshotSpecials.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        showSpecials: doc.data()?.show,
      }));

      const querySnapshotWine = await props.firebase.wines().get();
      const winesData = querySnapshotWine.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        showWine: doc.data()?.show,
      }));

      const querySnapshotCannedBeer = await props.firebase.cannedBeers().get();
      const cannedBeerData = querySnapshotCannedBeer.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        showCannedBeer: doc.data()?.show,
      }));

      // Merge beerList, specialsList, wineList, and cannedBeerList based on location ID
      const mergedLocations = beersData.map((location) => {
        const matchingSpecial = specialsData.find((special) => special.id === location.id);
        const matchingWine = winesData.find((wine) => wine.id === location.id);
        const matchingCannedBeer = cannedBeerData.find((cannedBeer) => cannedBeer.id === location.id);

        return {
          ...location,
          ...matchingSpecial,
          ...matchingWine,
          ...matchingCannedBeer,
          specialsList: matchingSpecial ? matchingSpecial.specialsList : [],
          wineList: matchingWine ? matchingWine.wineList : [],
          cannedBeerList: matchingCannedBeer ? matchingCannedBeer.cannedBeerList : [],
        };
      });

      setLocations(mergedLocations);
    };

    fetchLocations();
  }, [props.firebase]);

  const handleLocationChange = (event) => {
    const location = locations.find((loc) => loc.id === event.target.value);
    setSelectedLocation(location.id);
    setLocationName(location.locationName);
    setWineList(location.wineList || []);
    setBeerList(location.beerList);
    setSpecialsList(location.specialsList || []);
    setCannedBeerList(location.cannedBeerList || []);
    setSelectedLocationObject(location);
    console.log(location);
  };

  const handleSave = async () => {
    await props.firebase.beer(selectedLocation).set(
      {
        locationName,
        beerList,
        show: selectedLocationObject?.showBeer,
      },
      { merge: true },
    );

    await props.firebase.special(selectedLocation).set(
      {
        locationName,
        specialsList,
        show: selectedLocationObject?.showSpecials,
      },
      { merge: true },
    );

    await props.firebase.wine(selectedLocation).set(
      {
        locationName,
        wineList,
        show: selectedLocationObject?.showWine,
      },
      { merge: true },
    );

    await props.firebase.cannedBeer(selectedLocation).set(
      {
        locationName,
        cannedBeerList,
        show: selectedLocationObject?.showCannedBeer,
      },
      { merge: true },
    );

    alert('Changes saved successfully!');
  };

  const handleAddBeer = () => {
    setBeerList([...beerList, { name: '', abv: '', price: '', category: '', priority: '' }]);
  };

  const handleEditBeer = (index, field, value) => {
    const updatedBeerList = [...beerList];
    updatedBeerList[index][field] = value;
    setBeerList(updatedBeerList);
  };

  const handleRemoveBeer = (index) => {
    const updatedBeerList = [...beerList];
    updatedBeerList.splice(index, 1);
    setBeerList(updatedBeerList);
  };

  const handleAddSpecial = () => {
    setSpecialsList([...specialsList, { name: '', description: '', price: '' }]);
  };

  const handleEditSpecial = (index, field, value) => {
    const updatedSpecialsList = [...specialsList];
    updatedSpecialsList[index][field] = value;
    setSpecialsList(updatedSpecialsList);
  };

  const handleRemoveSpecial = (index) => {
    const updatedSpecialsList = [...specialsList];
    updatedSpecialsList.splice(index, 1);
    setSpecialsList(updatedSpecialsList);
  };

  const handleAddWine = () => {
    setWineList([...wineList, { name: '', description: '', price: '' }]);
  };

  const handleEditWine = (index, field, value) => {
    const updatedWineList = [...wineList];
    updatedWineList[index][field] = value;
    setWineList(updatedWineList);
  };

  const handleRemoveWine = (index) => {
    const updatedWineList = [...wineList];
    updatedWineList.splice(index, 1);
    setWineList(updatedWineList);
  };

  const handleAddCannedBeer = () => {
    setCannedBeerList([...cannedBeerList, { name: '', abv: '', price: '', category: '', priority: '' }]);
  };

  const handleEditCannedBeer = (index, field, value) => {
    const updatedCannedBeerList = [...cannedBeerList];
    updatedCannedBeerList[index][field] = value;
    setCannedBeerList(updatedCannedBeerList);
  };

  const handleRemoveCannedBeer = (index) => {
    const updatedCannedBeerList = [...cannedBeerList];
    updatedCannedBeerList.splice(index, 1);
    setCannedBeerList(updatedCannedBeerList);
  };

  return (
    <div className="admin-page">
      <h1>Edit Beer Menus</h1>
      <label>
        Select Location:
        <select onChange={handleLocationChange}>
          <option value="">Select a location</option>
          {locations.map((location) => (
            <option key={location.id} value={location.id}>
              {location.locationName}
            </option>
          ))}
        </select>
      </label>
      {selectedLocation && (
        <div>
          <label>
            Location Name:
            <input
              type="text"
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
            />
          </label>
          <hr />

          <div className="mb-5">
            <div className="h3">Beer List:</div>
            <button
              onClick={() => {
                setSelectedLocationObject({
                  ...selectedLocationObject,
                  showBeer: !selectedLocationObject.showBeer,
                });
              }}
              className=""
            >
              {selectedLocationObject?.showBeer ? 'HIDE BEER' : 'SHOW BEER'}
            </button>
            <table className="beer-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>ABV</th>
                  <th>Price</th>
                  {/* <th>Category</th> */}
                  <th>Priority</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {beerList.map((beer, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        value={beer.name}
                        onChange={(e) => handleEditBeer(index, 'name', e.target.value)}
                        className="name-input"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={beer.abv}
                        onChange={(e) => handleEditBeer(index, 'abv', e.target.value)}
                        className="small-input"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={beer.price}
                        onChange={(e) => handleEditBeer(index, 'price', e.target.value)}
                        className="small-input"
                      />
                    </td>
                    {/* <td>
                      <input
                        type="text"
                        value={beer.category}
                        onChange={(e) => handleEditBeer(index, 'category', e.target.value)}
                        className="small-input"
                      />
                    </td> */}
                    <td>
                      <input
                        type="text"
                        value={beer.priority}
                        onChange={(e) => handleEditBeer(index, 'priority', e.target.value)}
                        className="small-input"
                      />
                    </td>
                    <td>
                      <button className="remove" onClick={() => handleRemoveBeer(index)}>
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button className="add" onClick={handleAddBeer}>
              Add Beer
            </button>
          </div>

          <hr />

          <div className="mb-5">
            <div className="h3">Featured Cocktail:</div>
            <button
              onClick={() => {
                setSelectedLocationObject({
                  ...selectedLocationObject,
                  showSpecials: !selectedLocationObject.showSpecials,
                });

                console.log({
                  ...selectedLocationObject,
                  showSpecials: !selectedLocationObject.showSpecials,
                });
              }}
              className=""
            >
              {selectedLocationObject?.showSpecials === true ? 'HIDE Featured Cocktail' : 'SHOW Featured Cocktail'}
            </button>
            <table className="specials-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {specialsList.map((special, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        value={special.name}
                        onChange={(e) => handleEditSpecial(index, 'name', e.target.value)}
                        className="name-input"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={special.description}
                        onChange={(e) => handleEditSpecial(index, 'description', e.target.value)}
                        className="description-input"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={special.price}
                        onChange={(e) => handleEditSpecial(index, 'price', e.target.value)}
                        className="small-input"
                      />
                    </td>
                    <td>
                      <button className="remove" onClick={() => handleRemoveSpecial(index)}>
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button className="add" onClick={handleAddSpecial}>
              Add Featured Cocktail
            </button>
          </div>
          <hr />

          <div className="mb-5">
            <div className="h3">Wine List:</div>
            <button
              onClick={() => {
                setSelectedLocationObject({
                  ...selectedLocationObject,
                  showWine: !selectedLocationObject.showWine,
                });
              }}
              className=""
            >
              {selectedLocationObject?.showWine ? 'HIDE WINE' : 'SHOW WINE'}
            </button>
            <table className="specials-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {wineList.map((wine, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        value={wine.name}
                        onChange={(e) => handleEditWine(index, 'name', e.target.value)}
                        className="name-input"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={wine.description}
                        onChange={(e) => handleEditWine(index, 'description', e.target.value)}
                        className="description-input"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={wine.price}
                        onChange={(e) => handleEditWine(index, 'price', e.target.value)}
                        className="small-input"
                      />
                    </td>
                    <td>
                      <button className="remove" onClick={() => handleRemoveWine(index)}>
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button className="add" onClick={handleAddWine}>
              Add Wine
            </button>
          </div>

          <hr />

          <div className="mb-5">
            <div className="h3">Canned Beer List:</div>
            <button
              onClick={() => {
                setSelectedLocationObject({
                  ...selectedLocationObject,
                  showCannedBeer: !selectedLocationObject.showCannedBeer,
                });
              }}
              className=""
            >
              {selectedLocationObject?.showCannedBeer ? 'HIDE CANNED BEER' : 'SHOW CANNED BEER'}
            </button>
            <table className="canned-beer-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>ABV</th>
                  <th>Price</th>
                  {/* <th>Category</th> */}
                  <th>Priority</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {cannedBeerList.map((cannedBeer, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        value={cannedBeer.name}
                        onChange={(e) => handleEditCannedBeer(index, 'name', e.target.value)}
                        className="name-input"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={cannedBeer.abv}
                        onChange={(e) => handleEditCannedBeer(index, 'abv', e.target.value)}
                        className="small-input"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={cannedBeer.price}
                        onChange={(e) => handleEditCannedBeer(index, 'price', e.target.value)}
                        className="small-input"
                      />
                    </td>
                    {/* <td>
                      <input
                        type="text"
                        value={cannedBeer.category}
                        onChange={(e) => handleEditCannedBeer(index, 'category', e.target.value)}
                        className="small-input"
                      />
                    </td> */}
                    <td>
                      <input
                        type="text"
                        value={cannedBeer.priority}
                        onChange={(e) => handleEditCannedBeer(index, 'priority', e.target.value)}
                        className="small-input"
                      />
                    </td>
                    <td>
                      <button className="remove" onClick={() => handleRemoveCannedBeer(index)}>
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button className="add" onClick={handleAddCannedBeer}>
              Add Canned Beer
            </button>
          </div>

          <button className="save" onClick={handleSave}>
            Save
          </button>
        </div>
      )}
    </div>
  );
}

const condition = (authUser) =>
  authUser && authUser?.roles && !!authUser?.roles[ROLES.ADMIN];

export default compose(withFirebase, withAuthorization(condition))(BeerAdminPage);

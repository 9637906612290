import React, { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { ImFacebook, ImInstagram } from 'react-icons/im';
import { Link } from 'react-router-dom';
import DesktopLogo from '../../assets/Bunker_Trademarked_Desktop.png';
import MobileLogo from '../../assets/Bunker_Trademarked_Mobile.png';
import * as ROUTES from '../../constants/routes';
import GiftCardsPage from '../../pages/GiftCards';
import SignOutButton from '../../pages/SignOut';
import BookEventsButton from '../BookEventsButton/BookEventsButton';
import BookNowButton from '../BookNowButton/BookNowButton.js';
import { AuthUserContext } from '../Session';
import DropDownComponent from './DropDownComponent.js';
import './side-drawer.scss';

// Custom hook to track whether it's mobile view or not
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 992;
      if (mobile !== isMobile) {
        setIsMobile(mobile);
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return isMobile;
};

const Navigation = () => {
  const isMobile = useIsMobile();
  const [showGiftCards, setShowGiftCards] = useState(false);

  const NavigationAuth = React.memo(({ authUser }) => (
    <div className="side-drawer-container">
      <div className="side-drawer text-white">
        <div>
          <Link className="d-flex p-4" to={ROUTES.LANDING}>
            <img src={DesktopLogo} width="100%" className="mb-5 pt-5" alt="Desktop Logo" />
          </Link>
          <ul className="router-locations">
            <li className="mb-4"><SignOutButton /></li>
            <li className="mb-4"><Link className="text-white pb-3" to={ROUTES.ADMIN}>ADMIN PANEL</Link></li>
            <li className="mb-4"><Link className="text-white pb-3" to={ROUTES.BEER_ADMIN}>EDIT BEER</Link></li>
            <li className="mb-4">
              <Link className="text-white pb-3" to="/reviews?locationId=thebunkercliftonpark&locationName=The%20Bunker%20Clifton%20Park&username=John%20Doe&date=Friday%20January%2019th%2C%202024">
                REVIEWS PREVIEW
              </Link>
            </li>
            <li className="mb-4"><Link className="text-white pb-3" to="/calendar-admin">CALENDAR</Link></li>
          </ul>
        </div>
      </div>
    </div>
  ));

  const NavigationAuthMobile = React.memo(() => (
    <Navbar className="side-drawer-mobile justify-content-between w-100" expand="lg" collapseOnSelect={true}>
      <Link className="d-flex justify-content-between" style={{ height: '50px' }} to={ROUTES.LANDING}>
        <img src={MobileLogo} className="p-1" style={{ maxWidth: '170px' }} alt="Mobile Logo" />
      </Link>
      <Navbar.Toggle className="bg-light" aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-inverse">
          <Nav.Link as={Link} className="text-white" eventKey="1" to={ROUTES.ADMIN}>ADMIN PANEL</Nav.Link>
          <Nav.Link as={Link} className="text-white" eventKey="2" to={ROUTES.BEER_ADMIN}>EDIT BEER</Nav.Link>
          <Nav.Link as={Link} className="text-white" eventKey="3" to="/reviews?locationId=thebunkercliftonpark&locationName=The%20Bunker%20Clifton%20Park&username=John%20Doe&date=Friday%20January%2019th%2C%202024">Reviews Preview</Nav.Link>
          <Nav.Link as={Link} className="text-white" eventKey="1" to="/calendar-admin">CALENDAR</Nav.Link>
          <div className="mt-4"><SignOutButton /></div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  ));

const navItemGolf = {
  title: 'GOLF',
  options: [
    { to: ROUTES.LEAGUES, title: 'LEAGUES', id: 'leagues' },
    { to: ROUTES.MEMBERSHIP, title: 'MEMBERSHIP', id: 'membership' },
    { to: ROUTES.LESSONS, title: 'LESSONS', id: 'lessons' },
    { to: ROUTES.TOUR, title: 'TOUR', id: 'tour' }
  ]
};

const NavigationNonAuth = () => (
  <div className="side-drawer-container">
    <div className="side-drawer text-white">
      <div>
        <Link className="d-flex p-4" to={ROUTES.LANDING}>
          <img src={DesktopLogo} className="mb-5 pt-5" alt="Desktop Logo" />
        </Link>
        <ul className="router-locations">
          <li className="m-2 w-75"><BookNowButton url="https://thebunker-teefindr-live.web.app/location/thebunker" /></li>
          <li className="m-2 mb-3 w-75"><BookEventsButton /></li>
          <li className="mb-4"><Link className="text-white pb-3" to={ROUTES.LOCATION}>LOCATIONS</Link></li>
          <li className="mb-4"><a className="text-white" href="https://www.bunkerparties.com">EVENTS</a></li>
          <li className="mb-4"><Link className="text-white" to={ROUTES.MENUS}>MENUS</Link></li>
          <li className="mb-4">{DropDownComponent(navItemGolf)}</li>
          <li className="mb-4"><Link className="text-white" to={ROUTES.CAREERS}>CAREERS</Link></li>
          <li className="mb-4"><Link className="text-white" to={ROUTES.ABOUT_US}>ABOUT US</Link></li>
          <li className="mb-4"><Link className="text-white" to={ROUTES.CONTACT}>CONTACT</Link></li>
          <li className="mb-4"><Link className="text-white" to={ROUTES.FAQS}>FAQs</Link></li>
          <li className="mb-4"><Link to="#" className="text-white" onClick={() => setShowGiftCards(true)}>GIFT CARDS</Link></li>
          <li className="w-100">
            <div className="text-white d-flex justify-content-center mt-1 w-100">
              <a className="mx-2 text-white" target="_blank" href="https://www.instagram.com/getinthebunker/" rel="noopener noreferrer"><ImInstagram /></a>
              <a className="mx-2 text-white" target="_blank" href="https://www.facebook.com/getinthebunkerNY" rel="noopener noreferrer"><ImFacebook /></a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
);


  const NavigationNonAuthMobile = React.memo(() => (
    <Navbar className="side-drawer-mobile justify-content-between w-100" expand="lg" collapseOnSelect={true}>
      <Link className="d-flex justify-content-between" style={{ height: '50px' }} to={ROUTES.LANDING}>
        <img src={MobileLogo} className="p-1" style={{ maxWidth: '170px' }} alt="Mobile Logo" />
      </Link>
      <Navbar.Toggle className="bg-light" aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-inverse d-flex flex-align-center">
          <BookNowButton url="https://thebunker-teefindr-live.web.app/location/thebunker" />
          <BookEventsButton />
          <Nav.Link as={Link} className="text-white" eventKey="1" to={ROUTES.LANDING}>HOME</Nav.Link>
          <Nav.Link as={Link} className="text-white" eventKey="2" to={ROUTES.LOCATION}>LOCATIONS</Nav.Link>
          <Nav.Link as="a" className="text-white py-2" href="https://www.bunkerparties.com">EVENTS</Nav.Link>
          <Nav.Link as={Link} className="text-white" eventKey="6" to={ROUTES.MENUS}>MENUS</Nav.Link>
					{DropDownComponent(navItemGolf)}
          <Nav.Link as={Link} className="text-white" eventKey="2" to={ROUTES.CAREERS}>CAREERS</Nav.Link>
          <Nav.Link as={Link} className="text-white" eventKey="3" to={ROUTES.ABOUT_US}>ABOUT US</Nav.Link>
          <Nav.Link as={Link} className="text-white" eventKey="10" to={ROUTES.CONTACT}>CONTACT</Nav.Link>
          <Nav.Link as={Link} className="text-white" eventKey="5" to={ROUTES.FAQS}>FAQs</Nav.Link>
          <Nav.Link as={Link} to="#" className="text-white" eventKey="9" onClick={() => setShowGiftCards(true)}>GIFT CARDS</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  ));

  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser?.roles['ADMIN'] ? (
          ['/ad', '/admh'].includes(location.pathname) ? null : (
            <div className="bg-black">
              {isMobile ? <NavigationAuthMobile /> : <NavigationAuth authUser={authUser} />}
            </div>
          )
        ) : (
          <div className="bg-black">
            <GiftCardsPage
              show={showGiftCards}
              hide={() => setShowGiftCards(false)}
            />
            {isMobile ? <NavigationNonAuthMobile /> : <NavigationNonAuth />}
          </div>
        )
      }
    </AuthUserContext.Consumer>
  );
};

export default Navigation;

import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AboutPage from '../../pages/About';
import AccountPage from '../../pages/Account';
import AdPage from '../../pages/Ad';
import AdMH from '../../pages/AdMH';
import AdminPage from '../../pages/Admin';
import BeerPage from '../../pages/Beer';
import BeerAdminPage from '../../pages/BeerAdmin';
import CalendarAdmin from '../../pages/CalendarAdmin';
import CareersPage from '../../pages/Careers';
import ContactUsPage from '../../pages/ContactUs';
import FAQPage from '../../pages/FAQ';
import GiftCardsPage from '../../pages/GiftCards';
import HomePage from '../../pages/Home';
import LandingPage from '../../pages/Landing';
import LeaguesPage from '../../pages/Leagues';
import LessonsPage from '../../pages/Lessons';
import LocationPage from '../../pages/Location';
import MenusPage from '../../pages/Menus';
import PasswordForgetPage from '../../pages/PasswordForget';
import ShopPage from '../../pages/ShopPage';
import SignInPage from '../../pages/SignIn';
import SignUpPage from '../../pages/SignUp';
import Navigation from '../Navigation';
// import AdMHTest from '../../pages/AdMH';
import CourseTeesheet from '../../pages/CourseTeesheet';
import MembershipPage from '../../pages/Membership';
import MessagingPage from '../../pages/Messaging';
import RedirectPage from '../../pages/RedirectPage';
import ReviewsPage from '../../pages/ReviewsPage';
import SignTVPage from '../../pages/SignTV';
import TourPage from '../../pages/Tour';

import '../../styles/thebunker.scss';

import { positions, Provider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import * as ROUTES from '../../constants/routes';
import ScrollToTop from '../ScrollToTop.js';
import { withAuthentication } from '../Session';

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

const Footer = () => (
  <div className="footer">
    <p className="font-weight-bold">
      ©2024 The Bunker. All Rights Reserved.
    </p>
  </div>
);

const App = () => {
  return (
    <Provider template={AlertTemplate} {...options}>
      <div className="site-container">

				<Router>
        <Switch>

          <ScrollToTop>
            <Navigation />
            {/* <div className="h-100 w-100"> */}
              <Route
                exact
                path={ROUTES.LANDING}
                component={LandingPage}
              />
              <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route
                path={ROUTES.PASSWORD_FORGET}
                component={PasswordForgetPage}
              />
              <Route path={ROUTES.HOME} component={HomePage} />
              <Route path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route path={ROUTES.ADMIN} component={AdminPage} />
              <Route
                path={ROUTES.LOCATION}
                component={LocationPage}
              />
              <Route path={ROUTES.ABOUT_US} component={AboutPage} />
              {/* <Route path={ROUTES.PROMOTIONS} component={PromotionsPage} /> */}
              <Route path={ROUTES.FAQS} component={FAQPage} />
              <Route path={ROUTES.MENUS} component={MenusPage} />
              <Route
                path={ROUTES.GIFT_CARDS}
                component={GiftCardsPage}
              />
              <Route path={ROUTES.SHOP_GEAR} component={ShopPage} />
              <Route
                path={ROUTES.CONTACT}
                component={ContactUsPage}
              />
              <Route
                path={ROUTES.COURSE_TEESHEET}
                component={CourseTeesheet}
              />
              <Route
                path={ROUTES.EVENTS}
                render={() => (
                  <RedirectPage url="https://www.bunkerparties.com" />
                )}
              />
              <Route path={ROUTES.CAREERS} component={CareersPage} />
              <Route path={ROUTES.LESSONS} component={LessonsPage} />
              <Route path={ROUTES.LEAGUES} component={LeaguesPage} />
              <Route path={ROUTES.MEMBERSHIP} component={MembershipPage} />
							<Route path={ROUTES.TOUR} component={TourPage} />
              <Route path={ROUTES.AD} component={AdPage} />
              <Route path={ROUTES.ADMH} component={AdMH} />
							<Route path={ROUTES.BEERMH} component={BeerPage} />
							<Route path={ROUTES.BEER_ADMIN} component={BeerAdminPage} />
							<Route path={ROUTES.CALENDAR_ADMIN} component={CalendarAdmin} />
              {/* <Route path="/admhtest" component={AdMHTest} /> */}
              <Route
                path={ROUTES.MESSAGING}
                component={MessagingPage}
              />
              <Route path={ROUTES.SIGNTV} component={SignTVPage} />
							<Route path={ROUTES.REVIEWS} component={ReviewsPage} />
            {/* </div> */}
          </ScrollToTop>
        </Switch>
				</Router>
        {/* <Footer /> */}
      </div>
    </Provider>
  );
};

export default withAuthentication(App);
